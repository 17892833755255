<template>
    <div >
        <Header />     

            <div class="container container-global">
                <div class="wrap-form"> 
                    <div class="box-content">                                 
                        <div class="middle">
                            <h1>404 Not Found</h1> 
                        </div> 
                    </div>                      
                </div>                
            </div>
        <Footer />
    </div>
</template>

<script> 
import Header from '@/components/Layout/Header.vue' 
import Footer from '@/components/Layout/Footer.vue'; 

export default {
    name: 'notFound',
    components: {
        Header, 
        Footer, 
        
    },
    data:  function(){
        return {          
          

        }    
    }
}
</script>

<style scoped>
 
.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>